.sidebar {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: var(--gray-10);
    border-radius: 24px;
    padding: 32px;
}
.center-logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sidebar-items {
    
}