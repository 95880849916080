.sidebar-layout-grid {
    display: grid;
    grid-template-rows: 1fr 10fr 1fr;
    grid-template-columns: 1fr 2fr 7fr 1fr;
    height: 100vh;
    width: 100vw;
}
.content-container {
    grid-row: 2;
    grid-column: 3;
    box-sizing: border-box;
    padding: 32px;
}
.sidebar-container {
    grid-row: 2;
    grid-column: 2;
}