:root{
  --gray-70: rgba(0,0,0,0.7);
  --gray-50: rgba(0,0,0,0.5);
  --gray-20: rgba(0,0,0,0.2);
  --gray-10: rgba(0,0,0,0.1);
  --gray-5:  rgba(0,0,0,0.05);
  --text  : black; 
  --background: white;
  --inverted-text: white;
  --color: black;
  --muted: rgba(0,0,0,0.7);

  --error: rgba(200, 30, 30, 0.7);
  --success: rgba(44, 149, 37, 0.677);
}
body {
  background-color: var(--background);
}
* {
  font-family: 'Roboto';
}
input {
  background-color: var(--gray-5);
  color: var(--text);
  width: 100%;
  box-sizing: border-box;
  height: 50px;
  padding: 1rem;
  border-radius: 10px;
  border: none;
  font-size: 1rem;
}
button {
  font-size: 1rem;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  padding: 1rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}
::placeholder {
  color: var(--gray-50);
  font-size: 1.5rem;
  opacity: 1;
}

p {
  color: var(--text);
}
h1 {
  font-size: 2rem;
  color: var(--text);
  font-weight: 700;
}
h2 {
  font-size: 1.5rem;
  color: var(--text);
  font-weight: 700;
}
h3 {
  font-size: 1.5rem;
  color: var(--text);
  font-weight: 700;
}
h4 {
  font-size: 1.5rem;
  color: var(--gray-50);
  font-weight: 500;
}
.gray-70 {
  color: var(--gray-70);
}
.gray-50 {
  color: var(--gray-50);
}
.muted-text {
  color: var(--muted);
  font-size: 0.75rem;
}
.error {
  color: #FF1515;
  transition-duration: 500ms;
}
img {
  -webkit-user-drag: none;
}