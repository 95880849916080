.spinner {
	animation-name: rotate;
	animation-iteration-count: infinite;
	animation-duration: 800ms;
	animation-timing-function: ease-in-out;
	width: 32px;
	height: 32px;
	user-select: none;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}